<template>
  <div id="DadosBasicos" class="pa-3">
    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-2"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>

      <v-btn
        v-if="store_Empreendimento.acao == 'C'"
        @click="
          store_Empreendimento.acao = 'E';
          empreendimento_edicaoAux = {
            ...store_Empreendimento.empreendimento_edicao,
          };
          arrayMunicipios = [
            {
              codigo: store_Empreendimento.empreendimento_edicao.cod_municipio,
              nome:
                store_Empreendimento.empreendimento_edicao.empreend_municipio
                  .nome +
                ' - ' +
                store_Empreendimento.empreendimento_edicao.empreend_municipio
                  .uf,
            },
          ];
        "
        class="btn mr-1 pr-6"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="green accent-4"
          >mdi-pencil-outline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span
        >
      </v-btn>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <v-dialog
      v-model="store_Empreendimento.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
      <DadosBasicosModal />
    </v-dialog>
    <!-- CONTAINER "LABELS" -------------------------------------------------------->
    <v-form ref="form" class="mx-1 mt-3" lazy-validation>
      <v-row>
        <v-container class="mt-2">
          <v-row class="pt-0">
            <v-col
              cols="2"
              sm="2"
              class="d-flex pa-0"
              style="justify-content: center"
            >
              <div>
                <v-avatar
                  size="100px"
                  v-if="store_Empreendimento.acao == 'C'"
                  v-ripple
                  class="grey lighten-2"
                >
                  <v-img
                    :src="
                      store_Empreendimento.empreendimento_edicao.empreend_logo
                    "
                    height="100%"
                  >
                  </v-img>
                </v-avatar>

                <v-avatar
                  size="100px"
                  v-ripple
                  v-if="store_Empreendimento.acao != 'C'"
                  class="grey lighten-2"
                >
                  <span
                    v-if="!store_Empreendimento.empreendimento_edicao"
                    @click="dialog_crop = true"
                  >
                    Clique p/ adicionar avatar
                  </span>
                  <img
                    v-if="store_Empreendimento.empreendimento_edicao"
                    @click="dialog_crop = true"
                    height="60%"
                    :src="
                      store_Empreendimento.empreendimento_edicao.empreend_logo
                    "
                    alt="avatar"
                  />
                </v-avatar>
              </div>
            </v-col>
            <v-col cols="10" sm="10" class="pt-2 px-2 pb-0">
              <v-text-field
                v-model="
                  store_Empreendimento.empreendimento_edicao.empreend_nome
                "
                :rules="rulesNome"
                :readonly="store_Empreendimento.acao == 'C'"
                class="input-razao mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Nome do Empreendimento"
                placeholder="Nome do Empreendimento"
                light
                maxlength="80"
                autofocus
                filled
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="col-terceira-pf">
            <v-col cols="2" sm="2" class="pt-2 px-2 pb-0"> </v-col>
            <v-col cols="2" sm="2" class="pt-2 px-2 pb-0">
              <v-autocomplete
                label="Situação"
                placeholder="SELECIONE"
                :readonly="store_Empreendimento.acao == 'C'"
                auto-select-first
                filled
                outlined
                dense
                background-color="map-deep-merge white"
                v-model="
                  store_Empreendimento.empreendimento_edicao.empreend_situacao
                "
                :items="arrayAtivoInativo"
                item-text="name"
                :rules="rulesSituacao"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pt-2 px-2 pb-0">
              <v-text-field
                v-model="
                  store_Empreendimento.empreendimento_edicao.empreend_area
                "
                :readonly="store_Empreendimento.acao == 'C'"
                class="input-razao mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Área"
                light
                filled
                maxlength="20"
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" sm="2" class="pt-2 px-2 pb-0">
              <v-text-field
                v-model="
                  store_Empreendimento.empreendimento_edicao
                    .empreend_matr_numero
                "
                :readonly="store_Empreendimento.acao == 'C'"
                class="input-razao mb-0"
                loader-height="1"
                background-color="#FFF"
                label="N° Matricula"
                placeholder="N° Matricula"
                light
                maxlength="20"
                filled
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pt-0 px-2">
              <DatePicker
                v-model="
                  store_Empreendimento.empreendimento_edicao.empreend_matr_data
                "
                label="Data da Matrícula"
                style="font-size: 14px"
                :readonly="store_Empreendimento.acao == 'C'"
                class="mt-2"
                placeholder="dia/mês/ano"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
              >
              </DatePicker>
            </v-col>
          </v-row>
          <v-row class="col-terceira-pf">
            <v-col
              v-show="
                store_Empreendimento.acao == 'E' ||
                store_Empreendimento.acao == 'I'
              "
              cols="4"
              sm="4"
              class="pt-2 px-2 pb-0"
              style="padding-top: 24px !important; margin-top: -46px !important"
            >
              <v-autocomplete
                v-model="
                  store_Empreendimento.empreendimento_edicao.cod_municipio
                "
                :loading="loading"
                :search-input.sync="search"
                :items="arrayMunicipios"
                no-filter
                clearable
                label="Município"
                item-text="nome"
                item-value="codigo"
                auto-select-first
                filled
                outlined
                dense
                background-color="map-deep-merge white"
                :readonly="store_Empreendimento.acao == 'C'"
                :rules="rulesMunicipio"
              />
            </v-col>
            <v-col
              v-if="store_Empreendimento.acao == 'C'"
              cols="4"
              sm="4"
              class="pt-2 px-2 pb-0"
              style="padding-top: 24px !important"
            >
              <v-text-field
                v-model="textMunicipio"
                class="input-razao mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Munícipio"
                light
                filled
                outlined
                required
                :readonly="store_Empreendimento.acao == 'C'"
                dense
              ></v-text-field>
            </v-col>
            <v-col style="padding-top: 24px">
              <v-autocomplete
                label="Administradora"
                :readonly="store_Empreendimento.acao == 'C'"
                auto-select-first
                filled
                outlined
                dense
                v-model="store_Empreendimento.empreendimento_edicao.cod_empresa"
                background-color="map-deep-merge white"
                :items="arrayEmpresas"
                item-text="nome"
                item-value="cod_util"
                :rules="rulesAdministradora"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="col-quarta">
            <v-col cols="3" sm="3" class="pt-2 px-2 pb-0">
              <v-autocomplete
                label="Tipo"
                auto-select-first
                filled
                outlined
                dense
                background-color="map-deep-merge white"
                v-model="
                  store_Empreendimento.empreendimento_edicao.cod_imoveltipo
                "
                :items="arrayTipo"
                item-text="nome"
                item-value="cod_imoveltipo"
                :readonly="store_Empreendimento.acao == 'C'"
                :rules="rulesImovelTipo"
              ></v-autocomplete>
            </v-col>

            <v-col cols="9" sm="9" class="pt-2 px-2 pb-0">
              <v-autocomplete
                label="Cartório"
                :readonly="store_Empreendimento.acao == 'C'"
                auto-select-first
                filled
                outlined
                dense
                background-color="map-deep-merge white"
                v-model="
                  store_Empreendimento.empreendimento_edicao.cod_cartorio
                "
                :items="arrayCartorios"
                item-text="nome"
                item-value="codigo"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12" sm="12" class="pt-2 px-2 pb-0">
              <v-textarea
                outlined
                counter="200"
                maxlength="200"
                :readonly="store_Empreendimento.acao == 'C'"
                label="Observação"
                :rules="rulesTextArea"
                v-model="
                  store_Empreendimento.empreendimento_edicao.empreend_observacao
                "
                background-color="#FFF"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-footer
        v-if="store_Empreendimento.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3"
      >
        <v-btn
          @click="cancelar()"
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>

        <v-btn
          :loading="loading"
          @click="validate()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- ##################################### dessa parte pra baixo é o jeito antigo -->

    <!-- CONTAINER TABELAS -------------------------------------------------------->
    <v-container
      class="container mt-3 mx-0 pa-0"
      v-if="store_Empreendimento.acao == 'C'"
    >
      <!-- TABELA PROPRIETARIOS -------------------------------------------------------->
      <v-data-table
        class="tabela rounded-lg mb-3"
        :items="store_Empreendimento.empreendimento_edicao.proprietarios"
        :headers="headers_proprietarios"
        :loading="loading"
        :items-per-page="10"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
      >
        <template v-slot:top>
          <v-toolbar flat class="mb-n2 rounded-lg">
            <p
              class="font-primary font-weight-bold body-1 text-center mt-1"
              v-bind:style="{ color: COR_SUBTITULO }"
            >
              Proprietários -
              <span
                style="font-size: small"
                :style="`color: ${corPorcentagem} `"
              >
                {{ porc_usada }}%</span
              >
            </p>

            <v-spacer></v-spacer>

            <v-btn
              @click="store_Empreendimento.dialogProprietarios = true"
              class="btn pr-6"
              elevation="0"
            >
              <v-icon class="btn-icon mr-1" color="green accent-4"
                >mdi-plus</v-icon
              >
              <span
                class="caption font-weight-medium"
                :style="{ color: COR_SUBTITULO }"
                >Novo</span
              >
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:header>
          <thead>
            <tr>
              <th
                v-for="h in headers_proprietarios"
                :key="h.value"
                :class="h.class"
                class="py-2"
              >
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item }">
          <tr>
            <td width="10%">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item
                    v-for="(opcao, i) in opcoes"
                    :key="i"
                    @click="() => {}"
                    class="px-2"
                  >
                    <v-list-item-title v-if="opcao.title == 'Editar'">
                      <div @click="editar(item)">
                        <v-icon color="green" class="mr-2 icon-menu"
                          >mdi-pencil-outline
                        </v-icon>
                        {{ opcao.title }}
                      </div>
                    </v-list-item-title>

                    <v-list-item-title v-else-if="opcao.title == 'Excluir'">
                      <div
                        @click="
                          dialog_excluir_proprietario = true;
                          item_excluir_proprietario_aux = item;
                        "
                      >
                        <v-icon color="red" class="mr-2 icon-menu"
                          >mdi-delete-outline</v-icon
                        >
                        {{ opcao.title }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td>
              <span
                :style="
                  item.Empreend_Proprietario.pessoa_prop_titular == 'S'
                    ? 'font-weight: bold;'
                    : ''
                "
                >{{ item.pessoa_nome }}
              </span>
            </td>

            <td>
              {{ item.Empreend_Proprietario.empreend_prop_porcentagem }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- Dialogo excluir socio -->
      <v-container v-if="dialog_excluir_proprietario">
        <v-dialog
          v-model="dialog_excluir_proprietario"
          transition="dialog-bottom-transition"
          persistent
          max-width="410"
          class="pa-0"
        >
          <v-divider></v-divider>
          <v-card elevation="0" class="">
            <v-card-title class="text-h6" style="word-break: keep-all">
              Tem certeza que deseja EXCLUIR este proprietário?
            </v-card-title>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-4"
                color="primary"
                text
                @click="dialog_excluir_proprietario = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="btn white--text"
                color="primary accent-4"
                :loading="loading"
                @click="excluirProprietario()"
              >
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <!-- Fim Dialogo excluir socio -->
      <!-- DIALOGO PROPRIETARIO -------------------------------------------------------->
      <v-container v-if="store_Empreendimento.dialogProprietarios">
        <v-dialog
          v-model="store_Empreendimento.dialogProprietarios"
          scrollable
          persistent
          max-width="500px"
          max-height="600px"
        >
          <v-card>
            <!-- Cabecalho do Proprietario -->
            <v-card-title :style="{ 'background-color': COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width: 100%; height: 28px">
                <div class="pa-0" style="width: 100%">
                  <p
                    style="
                      color: white;
                      white-space: pre-line;
                      font-size: 16px !important;
                    "
                  >
                    Busca de Proprietário
                  </p>
                </div>
                <div class="pa-0 text-center" style="width: 40px">
                  <v-btn
                    icon
                    dark
                    v-on:click="
                      store_Empreendimento.dialogProprietarios = false;
                      store_Pessoa.pessoa_selecionado = null;
                    "
                    class="mt-n2"
                  >
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>

            <!-- Corpo do Proprietario -->
            <v-card-text class="pa-0" style="height: 500px">
              <PessoaConsultaMobile class="mt-n2" :editar_="true" />
            </v-card-text>

            <v-divider></v-divider>

            <!-- Rodape do Proprietario -->
            <v-card-actions
              class="d-flex justify-center pa-3"
              :style="{ 'background-color': COR_SECUNDARIA }"
            >
              <v-btn
                @click="
                  store_Empreendimento.dialogProprietarios = false;
                  store_Pessoa.acao_abas = false;
                  store_Pessoa.pessoa_selecionado = null;
                "
                class="mr-4 btn text-none"
                color="primary"
                text
              >
                Cancelar
              </v-btn>
              <v-btn
                class="btn text-none"
                color="primary"
                :disabled="!store_Pessoa.pessoa_selecionado"
                @click="dialog_proprietario = true"
              >
                Selecionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <!-- FIM DIALOGO PROPRIETARIO ---------------------------------------------------->

      <!-- Dialogo adicionar Proprietario -->
      <v-container v-if="dialog_proprietario">
        <v-dialog
          v-model="dialog_proprietario"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent
        >
          <v-card>
            <v-card-title :style="{ 'background-color': COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width: 100%; height: 28px">
                <div class="pa-0" style="width: 100%">
                  <p
                    style="
                      color: white;
                      white-space: pre-line;
                      font-size: 16px !important;
                    "
                  >
                    Proprietário
                  </p>
                </div>
                <div class="pa-0 text-center" style="width: 40px">
                  <v-btn
                    icon
                    dark
                    v-on:click="
                      dialog_proprietario = false;
                      select_proprietario_aux = null;
                    "
                    class="mt-n2"
                  >
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text style="overflow: hidden">
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3"
              >
                <v-row>
                  <v-col cols="12" sm="12" class="mb-n4">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      placeholder="Porcentagem"
                      type="number"
                      filled
                      autofocus
                      outlined
                      dense
                      :rules="porcentagemRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" class="mt-n2 mb-n2 pb-0">
                    <v-select
                      v-model="select_proprietario_aux"
                      :items="arraySIM_NAO"
                      placeholder="Titular?"
                      label="Titular?"
                      item-text="name"
                      item-value="value"
                      required
                      outlined
                      dense
                      :rules="titularRules"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center pa-3"
              :style="{ 'background-color': COR_SECUNDARIA }"
            >
              <v-btn
                @click="
                  dialog_proprietario = false;
                  select_proprietario_aux = null;
                "
                class="mr-4 btn text-none"
                color="primary"
                text
              >
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="
                  adicionarProprietario(store_Pessoa.pessoa_selecionado);
                  dialog_proprietario = false;
                "
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <!-- Fim Dialogo adicionar Proprietario -->

      <!-- Dialogo Editar Socio -->
      <v-container v-if="dialog_editar_proprietario">
        <v-dialog
          v-model="dialog_editar_proprietario"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent
        >
          <v-form v-model="validEditar" ref="formEditar">
            <v-card>
              <v-card-title :style="{ 'background-color': COR_PRINCIPAL }">
                <v-layout
                  nowrap
                  class="mt-n2"
                  style="width: 100%; height: 28px"
                >
                  <div class="pa-0" style="width: 100%">
                    <p
                      style="
                        color: white;
                        white-space: pre-line;
                        font-size: 16px !important;
                      "
                    >
                      Alterar porcentagem
                    </p>
                  </div>
                  <div class="pa-0 text-center" style="width: 40px">
                    <v-btn
                      icon
                      dark
                      v-on:click="
                        dialog_editar_proprietario = false;
                        select_proprietario_aux = null;
                      "
                      class="mt-n2"
                    >
                      <v-icon large class="title">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-layout>
              </v-card-title>
              <v-card-text class="pb-0 overflow-hidden">
                <v-row class="ma-0">
                  <v-col cols="12" sm="12" class="mb-n4" style="">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      placeholder="Porcentagem"
                      type="number"
                      required
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" class="mt-n2 mb-n4">
                    <v-select
                      v-model="select_proprietario_aux"
                      :items="arraySIM_NAO"
                      placeholder="Proprietário?"
                      label="Proprietário?"
                      item-text="name"
                      item-value="value"
                      required
                      outlined
                      dense
                      :rules="titularRules"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions
                class="d-flex justify-center pa-3"
                :style="{ 'background-color': COR_SECUNDARIA }"
              >
                <v-btn
                  @click="
                    dialog_editar_proprietario = false;
                    select_proprietario_aux = null;
                  "
                  class="mr-4 btn text-none"
                  color="primary"
                  text
                >
                  Cancelar
                </v-btn>
                <v-btn
                  :loading="loading"
                  @click="editarProprietario()"
                  class="btn text-none"
                  color="primary"
                  :disabled="!validEditar"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-container>
      <!-- Fim Dialog Editar Socio -->

      <!-- TABELA QUADRAS -------------------------------------------------------->
      <v-data-table
        class="tabela rounded-lg mb-3"
        :items="dados_quadras"
        :headers="headers_quadras"
        :loading="loading"
        :items-per-page="10"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
      >
        <template v-slot:top>
          <v-toolbar flat class="mb-n2 rounded-lg">
            <p
              class="font-primary font-weight-bold body-1 text-center mt-1"
              v-bind:style="{ color: COR_SUBTITULO }"
            >
              Quadras
            </p>

            <v-spacer></v-spacer>

            <v-btn
              @click="store_Empreendimento.dialogQuadras = true"
              class="btn pr-6"
              elevation="0"
            >
              <v-icon class="btn-icon mr-1" color="green accent-4"
                >mdi-pencil-outline</v-icon
              >
              <span
                class="caption font-weight-medium"
                v-bind:style="{ color: COR_SUBTITULO }"
                >Novo</span
              >
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:header>
          <thead>
            <tr>
              <th
                v-for="h in headers_quadras"
                :key="h.value"
                :class="h.class"
                class="py-2"
              >
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item }">
          <tr>
            <td width="10%">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item
                    v-for="(item, i) in opcoes"
                    :key="i"
                    @click="() => {}"
                    class="px-2"
                  >
                    <v-list-item-title v-if="item.title == 'Editar'">
                      <div @click="store_Empreendimento.dialogQuadras = true">
                        <v-icon color="green" class="mr-2 icon-menu"
                          >mdi-pencil-outline
                        </v-icon>
                        {{ item.title }}
                      </div>
                    </v-list-item-title>

                    <v-list-item-title v-else-if="item.title == 'Excluir'">
                      <v-icon color="red" class="mr-2 icon-menu"
                        >mdi-delete-outline</v-icon
                      >
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>

            <td>
              {{ item.nome }}
            </td>

            <td>
              {{ item.qtde_lotes }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog
        v-model="store_Empreendimento.dialogQuadras"
        transition="dialog-bottom-transition"
        max-width="400"
        class="pa-0"
      >
        <QuadrasModal />
      </v-dialog>

      <!-- TABELA FASE -------------------------------------------------------->
      <v-data-table
        class="tabela rounded-lg mb-3"
        :items="array_fase"
        :headers="headers_fase"
        :loading="loading"
        :items-per-page="9999"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
      >
        <template v-slot:top>
          <v-toolbar flat class="mb-n2 rounded-lg">
            <p
              class="font-primary font-weight-bold body-1 text-center mt-1"
              v-bind:style="{ color: COR_SUBTITULO }"
            >
              Fase
            </p>

            <v-spacer></v-spacer>

            <v-btn @click="novaFase()" class="btn pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" color="green accent-4"
                >mdi-pencil-outline</v-icon
              >
              <span
                class="caption font-weight-medium"
                v-bind:style="{ color: COR_SUBTITULO }"
                >Novo</span
              >
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:header>
          <thead>
            <tr>
              <th
                v-for="h in headers_fase"
                :key="h.value"
                :class="h.class"
                class="py-2"
              >
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item }">
          <tr>
            <td width="10%">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item
                    v-for="(item_text, i) in opcoes"
                    :key="i"
                    @click="() => {}"
                    class="px-2"
                  >
                    <v-list-item-title v-if="item_text.title == 'Editar'">
                      <div @click="editarFase(item)">
                        <v-icon color="green" class="mr-2 icon-menu">
                          mdi-pencil-outline
                        </v-icon>
                        {{ item_text.title }}
                      </div>
                    </v-list-item-title>
                    <v-list-item-title
                      @click="dialog_excluir_fase = item"
                      v-else-if="item_text.title == 'Excluir'"
                    >
                      <v-icon color="red" class="mr-2 icon-menu">
                        mdi-delete-outline
                      </v-icon>
                      {{ item_text.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>

            <td>
              {{ item.nome_fase }}
            </td>

            <td>
              {{ item.porcentagem }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-container v-if="dialog_FaseModal">
        <v-dialog
          v-model="dialog_FaseModal"
          transition="dialog-bottom-transition"
          max-width="400"
          persistent
          class="pa-0"
        >
          <FaseModal
            :dialog_FaseModal.sync="dialog_FaseModal"
            :dados_editar="dados_editar"
            @fases="fases"
          />
        </v-dialog>
      </v-container>

      <v-container v-if="dialog_excluir_fase">
        <v-dialog max-width="430" :value="dialog_excluir_fase" persistent>
          <v-card class="container pa-0">
            <v-card-title
              v-bind:style="{ 'background-color': COR_PRINCIPAL }"
              class="justify-space-between py-2 px-3"
            >
              <span class="title-page body-1">Fase</span>
              <v-btn
                @click="dialog_excluir_fase = null"
                icon
                dark
                color="#F2F6F7"
              >
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title>
              Tem certeza que deseja excluir a fase?
            </v-card-title>

            <v-card-text class="pt-1 pb-10">
              <p>
                Fase: <b>{{ dialog_excluir_fase.nome_fase }}</b>
              </p>
            </v-card-text>

            <v-footer
              absolute
              color="#F2F6F7"
              elevation="0"
              class="d-flex justify-center pa-3"
            >
              <v-btn
                @click="dialog_excluir_fase = null"
                class="mr-4 btn caption font-weight-medium"
                color="primary"
                text
              >
                Cancelar
              </v-btn>

              <v-btn
                @click="excluirFase()"
                class="btn white--text caption font-weight-medium"
                color="primary accent-4"
              >
                Excluir
              </v-btn>
            </v-footer>
          </v-card>
          <!-- {{ dialog_excluir_fase }} -->
        </v-dialog>
      </v-container>

      <!-- TABELA RATEIOS -------------------------------------------------------->
      <v-data-table
        class="tabela rounded-lg mb-3"
        :items="dados_rateios"
        :headers="headers_rateios"
        :loading="loading"
        :items-per-page="10"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
      >
        <template v-slot:top>
          <v-toolbar flat class="mb-n2 rounded-lg">
            <p
              class="font-primary font-weight-bold body-1 text-center mt-1"
              v-bind:style="{ color: COR_SUBTITULO }"
            >
              Rateios
            </p>

            <v-spacer></v-spacer>

            <v-btn
              @click="store_Empreendimento.dialogRateios = true"
              class="btn pr-6"
              elevation="0"
            >
              <v-icon class="btn-icon mr-1" color="green accent-4"
                >mdi-pencil-outline</v-icon
              >
              <span
                class="caption font-weight-medium"
                v-bind:style="{ color: COR_SUBTITULO }"
                >Novo</span
              >
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:header>
          <thead>
            <tr>
              <th
                v-for="h in headers_rateios"
                :key="h.value"
                :class="h.class"
                class="py-2"
              >
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item }">
          <tr>
            <td width="10%">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item
                    v-for="(item, i) in opcoes"
                    :key="i"
                    @click="() => {}"
                    class="px-2"
                  >
                    <v-list-item-title v-if="item.title == 'Editar'">
                      <div @click="store_Empreendimento.dialogRateios = true">
                        <v-icon color="green" class="mr-2 icon-menu"
                          >mdi-pencil-outline
                        </v-icon>
                        {{ item.title }}
                      </div>
                    </v-list-item-title>

                    <v-list-item-title v-else-if="item.title == 'Excluir'">
                      <v-icon color="red" class="mr-2 icon-menu"
                        >mdi-delete-outline</v-icon
                      >
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>

            <td>
              {{ item.setor }}
            </td>

            <td>
              {{ item.porcentagem_entrada }}
            </td>

            <td>
              {{ item.porcentagem_parcelas }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog
        v-model="store_Empreendimento.dialogRateios"
        transition="dialog-bottom-transition"
        max-width="400"
        class="pa-0"
      >
        <RateiosModal />
      </v-dialog>
    </v-container>
    <!-- FIM CONTAINER TABELAS -------------------------------------------------------->

    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-dialog
      v-if="dialog_crop"
      :value="true"
      persistent
      style="width: 330"
      :max-width="330"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>Logo</v-card-title>
        <v-card-text class="pt-7">
          <VueCropImage
            :img="store_Empreendimento.empreendimento_edicao.empreend_logo"
            :imgsync.sync="
              store_Empreendimento.empreendimento_edicao.empreend_logo
            "
            :dialog_crop.sync="dialog_crop"
            ref="VueCropImage"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- --------------------------------------------------- -->
  </div>
</template>

<script>
import store_Pessoa from "../../Pessoas/store_Pessoa";

import {
  arraySIM_NAO,
  COR_SUBTITULO,
  arrayAtivoInativo,
  arraySexo,
  arrayUF,
  COR_PRINCIPAL,
  COR_SECUNDARIA,
} from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import store_site from "../../../store/store_site";

import DadosBasicosModal from "../DadosBasicos/DadosBasicosModal.vue";
// import ProprietariosModal from "../DadosBasicos/ProprietariosModal.vue";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue";
import QuadrasModal from "../DadosBasicos/QuadrasModal.vue";
import FaseModal from "../DadosBasicos/FaseModal.vue";
import RateiosModal from "../DadosBasicos/RateiosModal.vue";
import { API } from "../../../services/API";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VueCropImage from "../../ComponentesGlobais/CropImage/VueCropImage.vue";
import moment from "moment";
// import _ from 'lodash'

export default {
  name: "DadosBasicos",

  components: {
    DadosBasicosModal,
    // ProprietariosModal,
    VueCropImage,
    QuadrasModal,
    FaseModal,
    RateiosModal,
    DatePicker,
    PessoaConsultaMobile,
  },

  data() {
    return {
      store_Pessoa: store_Pessoa,
      select: null,
      search: null,
      autocompleteMunicipio: null,
      apiSource: null,

      COR_SUBTITULO: COR_SUBTITULO,
      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      store_Empreendimento: store_Empreendimento,
      empreendimento_edicaoAux: null,
      loading: false,
      store_site: store_site,
      select_proprietario_aux: "N",
      array_fase: [],
      dialog_FaseModal: false,
      dialog_excluir_fase: null,

      data_matricula_Aux: null,
      arraySIM_NAO: arraySIM_NAO,
      item_editar_proprietario_aux: null,
      item_excluir_proprietario_aux: null,

      arrayAtivoInativo: arrayAtivoInativo,
      arraySexo: arraySexo,
      arrayMunicipios: [],
      arrayCartorios: null,
      arrayEmpresas: null,
      arrayTipo: null,
      editar_porc_aux: null,
      dados_editar: [],
      //textMunicipio: null,

      arrayUF: arrayUF,

      dialog_crop: null,
      dialog_proprietario: null,
      dialog_editar_proprietario: null,
      dialog_excluir_proprietario: false,
      validAdicionar: null,
      validEditar: null,

      dados: {
        codigo: 1112,
        empreendimento: "JARDIM BONSUCESSO",
        situacao: "ativo",
        administradora: "PARRA IMOBILIÁRIA LTDA",
        area: "0000",
        municipio: "FRANCA",
        uf: "SP",
        tipo: "TERRENO",
        matricula: "1102",
        data: "20/08/2012",
        cartorio: "2 OFICIAL DE REGISTRO DE IMÓVEIS DE FRANCA",
        observacao: "teste",
      },

      /* TITULOS PROPRIETARIOS */
      headers_proprietarios: [
        {
          text: "",
          value: "",
        },
        {
          text: "Nome",
          value: "nome",
          class: "title-text",
        },
        {
          text: "Porcentagem",
          value: "porcentagem",
          class: "title-text",
        },
      ],

      /* TABELA PROPRIETARIOS */
      dados_proprietarios: [
        {
          nome: "PARRA IMOBILIÁRIA",
          porcentagem: "100",
        },
      ],

      /* TITULOS QUADRAS */
      headers_quadras: [
        {
          text: "",
          value: "",
        },
        {
          text: "Nome da quadra",
          value: "nome_quadra",
          class: "title-text",
        },
        {
          text: "Quantidade de Lotes",
          value: "qtde_lotes",
          class: "title-text",
        },
      ],

      /* TITULOS FASE */
      headers_fase: [
        {
          text: "",
          value: "",
        },
        {
          text: "Nome da fase",
          value: "nome_fase",
          class: "title-text",
        },
        {
          text: "Porcentagem",
          value: "porcentagem",
          class: "title-text",
        },
      ],

      /* TABELA QUADRAS */
      dados_quadras: [
        {
          nome: "16",
          qtde_lotes: "10",
        },
      ],

      /* TITULOS RATEIOS */
      headers_rateios: [
        {
          text: "",
          value: "",
        },
        {
          text: "Setor / Departamento",
          value: "setor_departamento",
          class: "title-text",
        },
        {
          text: "Porcentagem sobre entrada",
          value: "porcentagem_entrada",
          class: "title-text",
        },
        {
          text: "Porcentagem sobre parcelas",
          value: "porcentagem_parcelas",
          class: "title-text",
        },
      ],

      /* TABELA RATEIOS */
      dados_rateios: [
        {
          setor: "Gerência",
          porcentagem_entrada: "5,0000",
          porcentagem_parcelas: "5,0000",
        },
      ],

      /* MENU EDIÇÃO (BUTTON DOTS) */
      opcoes: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

      rulesTextArea: [
        (v) =>
          (v || "").length <= 255 ||
          "A observação deve ter no máximo 255 caracteres",
      ],

      rulesNome: [
        (value) => !!value || "O Nome do Empreendimento é obrigatório",
      ],

      rulesMunicipio: [
        (value) => !!value || "O Município do Empreendimento é obrigatório",
      ],

      rulesAdministradora: [
        (value) =>
          !!value || "A Administradora do Empreendimento é obrigatório",
      ],

      rulesSituacao: [
        (value) => !!value || "A Situação do Empreendimento é obrigatório",
      ],

      rulesImovelTipo: [
        (value) =>
          !!value || "O Tipo do imóvel do Empreendimento é obrigatório",
      ],

      rulesDataMatr: [
        (value) => !!value || "A Data da Matrícula é obrigatória",
      ],
      porcentagemRules: [
        (value) => !!value || "A porcentagem é obrigatória",
        (value) =>
          (value > 0 && value < 101) || "A porcentagem deve ter entre 1 e 100",
        // (value) =>
        //   (!value || value.length <= 3) || "A porcentagem deve ter até 3 caracteres",
        // (value) =>
        // ( !value ||  /^\d+$/.test(value)) || 'A porcentagem não é válida',
      ],
      titularRules: [(value) => !!value || "O titular é obrigatório"],
    };
  },

  computed: {
    textMunicipio() {
      return (
        this.store_Empreendimento.empreendimento_edicao?.empreend_municipio
          ?.nome +
        " - " +
        this.store_Empreendimento.empreendimento_edicao?.empreend_municipio?.uf
      );
    },
    porc_usada() {
      let cont = 0;
      if (
        this.store_Empreendimento?.empreendimento_edicao.proprietarios?.length >
        0
      ) {
        for (
          let index = 0;
          index <
          this.store_Empreendimento?.empreendimento_edicao.proprietarios
            ?.length;
          index++
        ) {
          const element =
            this.store_Empreendimento.empreendimento_edicao.proprietarios[index]
              .Empreend_Proprietario.empreend_prop_porcentagem;
          cont += Number(element);
        }
      }
      return cont;
    },

    corPorcentagem() {
      return this.porc_usada != 100 ? "red" : "green";
    },
  },

  updated() {
    // let cont = 0
    // for (let index = 0; index < this.store_Empreendimento.empreendimento_edicao.proprietarios.length; index++) {
    //   const element = this.store_Empreendimento.empreendimento_edicao.proprietarios[index].Empreend_Proprietario.empreend_prop_porcentagem;
    //   cont += element;
    // }
    // this.porc_usada = cont;
  },

  watch: {
    search(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },
  },
  async mounted() {
    await this.empresas();
    await this.cartorios();
    await this.imovelTipo();
    await this.fases();
  },

  destroyed() {
    // this.pessoa_edicaoAux = null;
    // this.selecionarProprietario();
  },

  methods: {
    async excluirFase() {
      this.loading = true;
      let response = await API.delete(
        `/fase_empreendimento/${this.dialog_excluir_fase.cod_empreend_fase}`
      );

      if (response.status === 200) {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_msg = response.data.result.result.trim();
        this.store_site.alert = true;
      } else {
        this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_msg = response.data.result.trim();
        this.store_site.alert = true;
      }
      this.loading = false;

      this.dialog_excluir_fase = null;
      this.fases();
    },

    novaFase() {
      this.dados_editar = {};
      this.dados_editar.acao = "novo";
      this.dialog_FaseModal = true;
    },

    editarFase(item) {
      this.dados_editar = item;
      this.dados_editar.acao = "editar";
      this.dialog_FaseModal = true;
    },

    async fases() {
      if (!!this.$route.params.cod_empreendimento) {
        const { data } = await API.get(`fase_empreendimento`, {
          params: { cod_empreendimento: this.$route.params.cod_empreendimento },
        });
        this.array_fase = data.result;
      }
    },

    async excluirProprietario() {
      this.loading = true;
      //Monta os dados para fazer a requisição
      let aux = {
        cod_empreend_proprietario:
          this.item_excluir_proprietario_aux.Empreend_Proprietario
            .cod_empreend_proprietario,
        acao: "D",
        cod_empreendimento:
          this.store_Empreendimento.empreendimento_edicao.cod_empreendimento,
      };
      let ls_Proprietario = await this.store_Empreendimento.postProprietarios(
        aux
      );

      //Retorno do backend
      if (ls_Proprietario.result.message == "success") {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_msg = ls_Proprietario.result.result.trim();
        this.store_site.alert = true;

        //Atualiza a tabela com os novos dados
        this.store_Empreendimento.empreendimento_edicao.proprietarios =
          this.store_Empreendimento.empreendimento_edicao.proprietarios.filter(
            (value) =>
              value.Empreend_Proprietario.cod_empreend_proprietario !=
              this.item_excluir_proprietario_aux.Empreend_Proprietario
                .cod_empreend_proprietario
          );

        //verifica se existe 1 socio como titular
        let seta_socio_como_titular = false;
        if (
          this.store_Empreendimento.empreendimento_edicao.proprietarios.length >
          0
        ) {
          for (let value of this.store_Empreendimento.empreendimento_edicao
            .proprietarios) {
            if (value.Empreend_Proprietario.pessoa_prop_titular == "S") {
              seta_socio_como_titular = false;
            } else {
              seta_socio_como_titular = true;
            }
          }
          // se não existe nenhum socio como titular, seta o primeiro
          if (seta_socio_como_titular) {
            let titular = {};
            titular =
              this.store_Empreendimento.empreendimento_edicao.proprietarios[0]
                .Empreend_Proprietario;
            titular.pessoa_prop_titular = "S";
            let lo_socio_titular = { ...titular, acao: "E" };

            let ls_Socio = await this.store_Empreendimento.postProprietarios(
              lo_socio_titular
            );
          }
        }
      } else {
        this.store_site.alert_cor = "#A00000";
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_msg =
          "Não foi possível excluir este proprietário!";
        this.store_site.alert = true;
      }

      this.loading = false;
      this.dialog_excluir_proprietario = false;
    },

    async editar(item) {
      this.dialog_editar_proprietario = true;
      this.item_editar_proprietario_aux = item;
      this.editar_porc_aux =
        item.Empreend_Proprietario.empreend_prop_porcentagem;
      this.select_proprietario_aux =
        item.Empreend_Proprietario.pessoa_prop_titular;
    },

    async editarProprietario() {
      this.loading = true;
      //Monta os dados para fazer a requisição
      let aux = {
        cod_empreend_proprietario:
          this.item_editar_proprietario_aux.Empreend_Proprietario
            .cod_empreend_proprietario,
        cod_empreendimento:
          this.store_Empreendimento.empreendimento_edicao.cod_empreendimento,
        cod_pessoa: this.item_editar_proprietario_aux.cod_pessoa,
        empreend_prop_porcentagem: this.editar_porc_aux,
        pessoa_prop_titular: this.select_proprietario_aux,
        acao: "E",
      };

      let ls_Socio = await this.store_Empreendimento.postProprietarios(aux);

      //Atualiza o banco com os novos dados do titular
      let novo_lo_Proprietario;
      if (this.select_proprietario_aux == "S") {
        for (let value of this.store_Empreendimento.empreendimento_edicao
          .proprietarios) {
          if (
            value.Empreend_Proprietario.cod_pessoa !=
            this.item_editar_proprietario_aux.Empreend_Proprietario.cod_pessoa
          ) {
            value.Empreend_Proprietario.pessoa_prop_titular = "N";
            // value.Empreend_Proprietario.empreend_prop_porcentagem = aux.pessoa_prop_titular

            value.pessoa_prop_titular = "N";
            let novoValue = value.Empreend_Proprietario;
            novo_lo_Proprietario = { ...novoValue, acao: "E" };
            await this.store_Empreendimento.postProprietarios(
              novo_lo_Proprietario
            );
          } else {
            value.Empreend_Proprietario.pessoa_prop_titular = "S";
            value.Empreend_Proprietario.empreend_prop_porcentagem =
              aux.empreend_prop_porcentagem;
          }
        }
      } else {
        for (let value of this.store_Empreendimento.empreendimento_edicao
          .proprietarios) {
          if (
            value.Empreend_Proprietario.cod_pessoa ==
            this.item_editar_proprietario_aux.cod_pessoa
          ) {
            value.Empreend_Proprietario.pessoa_prop_titular = "N";
            value.Empreend_Proprietario.empreend_prop_porcentagem =
              aux.empreend_prop_porcentagem;
          }
        }
      }

      this.loading = false;
      //Retorno do backend
      if (
        (ls_Socio.result &&
          ls_Socio.result.message &&
          ls_Socio.result.message == "success") ||
        (ls_Socio.status && ls_Socio.status == 200)
      ) {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_msg = ls_Socio.result.result.trim();
        this.store_site.alert = true;
      } else {
        this.store_site.alert_cor = "#A00000";
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_msg = ls_Socio.errors.trim();
        this.store_site.alert = true;
      }

      this.dialog_editar_proprietario = false;
    },

    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        var lo_JSON = {
          ...this.store_Empreendimento.empreendimento_edicao,
          titulo_ocorrencia: "Alterações de dados básicos",
        };

        var ls_Res;
        this.loading = true;

        if (this.store_Empreendimento.acao == "E") {
          // console.log('EDICAO', lo_JSON)
          ls_Res = await this.store_Empreendimento.EmpreendimentoPut(lo_JSON);
          // Incluindo Contato ----------------
        } else {
          // console.log('INSERCAO', lo_JSON)
          ls_Res = await this.store_Empreendimento.EmpreendimentoPost(lo_JSON);
          this.$router.push(
            "/empreendimentocontainer/" + ls_Res.result.data.cod_empreendimento
          );
        }

        if (
          ls_Res.result &&
          ls_Res.result.message &&
          ls_Res.result.message == "success"
        ) {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          // Buscando Novamente o Registro Salvo
          var lo_params = {
            cod_empreendimento:
              this.store_Empreendimento.empreendimento_edicao
                .cod_empreendimento ?? ls_Res.result.data.cod_empreendimento,
          };
          const lo_Res = await this.store_Empreendimento.EmpreendimentoGet(
            lo_params
          );
          if (!!lo_Res)
            this.store_Empreendimento.empreendimento_edicao = {
              ...lo_Res,
            };

          this.store_Empreendimento.acao = "C";
          this.empreendimento_edicaoAux =
            this.store_Empreendimento.empreendimento_edicao;
        } else {
          this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
      }
    },

    async adicionarProprietario() {
      const lb_valido_adicionar = this.$refs.formAdicionar.validate();
      if (lb_valido_adicionar) {
        this.loading = true;

        //Verifica se o proprietário já está vinculado a este cadastro
        if (
          this.store_Empreendimento.empreendimento_edicao.proprietarios.length >
          0
        ) {
          for (
            let index = 0;
            index <
            this.store_Empreendimento.empreendimento_edicao.proprietarios
              .length;
            index++
          ) {
            const element =
              this.store_Empreendimento.empreendimento_edicao.proprietarios[
                index
              ].Empreend_Proprietario;

            if (
              element.cod_pessoa ==
              this.store_Pessoa.pessoa_selecionado.cod_pessoa
            ) {
              this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
              this.store_site.alert_timeout = 5000;
              this.store_site.alert_msg = "Sócio já cadastrado";
              this.store_site.alert = true;
              this.loading = false;
              return;
            }
          }
        }

        //Monta os dados para fazer a requisição
        let aux = {
          cod_empreendimento:
            this.store_Empreendimento.empreendimento_edicao.cod_empreendimento,
          cod_pessoa: this.store_Pessoa.pessoa_selecionado.cod_pessoa,
          empreend_prop_porcentagem: this.editar_porc_aux,
          pessoa_prop_titular: this.select_proprietario_aux,
          acao: "I",
        };

        if (
          this.store_Empreendimento.empreendimento_edicao.proprietarios
            .length == 0
        ) {
          aux.pessoa_prop_titular = "S";
        }

        let ls_proprietario = await this.store_Empreendimento.postProprietarios(
          aux
        );

        //Atualiza a tabela com os dados novos
        let auxiliar = {};

        auxiliar = { Empreend_Proprietario: aux };
        auxiliar.pessoa_nome = this.store_Pessoa.pessoa_selecionado.pessoa_nome;
        auxiliar.Empreend_Proprietario.cod_empreend_proprietario =
          ls_proprietario.result.data.cod_empreend_proprietario;

        this.store_Empreendimento.empreendimento_edicao.proprietarios.push(
          auxiliar
        );

        //Atualiza o banco com os novos dados do titular
        let novo_lo_Proprietario;
        if (this.select_proprietario_aux == "S") {
          for (let value of this.store_Empreendimento.empreendimento_edicao
            .proprietarios) {
            if (
              value.Empreend_Proprietario.cod_pessoa !=
              this.store_Pessoa.pessoa_selecionado.cod_pessoa
            ) {
              value.Empreend_Proprietario.pessoa_prop_titular = "N";

              value.pessoa_prop_titular = "N";
              let novoValue = value.Empreend_Proprietario;
              novo_lo_Proprietario = { ...novoValue, acao: "E" };

              await this.store_Empreendimento.postProprietarios(
                novo_lo_Proprietario
              );
            }
          }
        }

        //Retorno do backend
        if (
          (ls_proprietario.result &&
            ls_proprietario.result.message &&
            ls_proprietario.result.message == "success") ||
          (ls_proprietario.status && ls_proprietario.status == 200)
        ) {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_proprietario.result.result.trim();
          this.store_site.alert = true;
        } else {
          this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Socio.errors.trim();
          this.store_site.alert = true;
        }

        this.loading = false;
        this.store_Empreendimento.dialogProprietarios = false;
      }
    },

    selecionarProprietario(elem) {
      // if (!elem) {
      // this.proprietario = {
      //   cod_empreendimento  :  this.$route.params.cod_empreendimento,
      //   cod_pessoa          :  null,
      // }
      // }
      // else {
      //   this.proprietario = elem;
      //   this.proprietario.cod_empreendimento = this.$route.params.cod_empreendimento;
      // }

      this.store_Pessoa.dialogProprietarioExcluir = false;
      this.store_Pessoa.dialogProprietario = false;
    },
    busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        const ls_Rel_Municipios = await API.get(`municipio`, val);
        if (Array.isArray(ls_Rel_Municipios.data.result)) {
          ls_Rel_Municipios.data.result.map(
            (el) => (el.nome = `${el.nome} - ${el.uf}`)
          );
          this.arrayMunicipios = ls_Rel_Municipios.data.result;
        }
      }, 600);
    },

    querySearch(query) {
      let p_params = { params: { filtro: query } };
      this.loading = true;
      this.busca_timeout(p_params);
      this.loading = false;
    },

    async imovelTipo() {
      const ls_Rel_Tipo = await API.get(`imovel/tipo`);
      this.arrayTipo = ls_Rel_Tipo.data.result;
    },
    async cartorios() {
      const ls_Rel_Cartorios = await API.get(`cartorio`);
      this.arrayCartorios = ls_Rel_Cartorios.data.result;
    },
    async empresas() {
      const ls_RelEmpresas = await API.get(`configuracao/empresa`);
      this.arrayEmpresas = ls_RelEmpresas.data.result;
    },

    cancelar() {
      if (this.store_Empreendimento.acao == "I") {
        this.empreendimento_edicaoAux = null;
        this.$router.push("empreendimentoconsulta");
      } else if (this.store_Empreendimento.acao == "E") {
        this.store_Empreendimento.empreendimento_edicao = {
          ...this.empreendimento_edicaoAux,
        };
        // this.conjuge                    = {...this.store_Empreendimento.pessoa_edicao.pessoa_conjuge};
      }
      this.store_Empreendimento.acao = "C";
    },
  },
};
</script>

<style scoped>
#DadosBasicos {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

.col-terceira-pf div:nth-child(2),
.col-terceira-pj div:nth-child(2) {
  margin-top: -46px;
}

.col-terceira-pj div:nth-child(1) {
  margin-bottom: 4px;
}

.col-terceira-pf div:nth-child(3) {
  margin-top: -46px;
}

.col-terceira-pf div:nth-child(4) {
  margin-top: -46px;
}
.col-terceira-pf div:nth-child(5) {
  margin-top: -46px;
}

.col-quarta div:nth-child(1) {
  margin-top: -14px;
}
.col-quarta div:nth-child(2) {
  margin-top: -14px;
}

@media (max-width: 599px) {
  #DadosBasicos {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#DadosBasicos::-webkit-scrollbar {
  width: 5px;
}

#DadosBasicos::-webkit-scrollbar-button {
  padding: 1px;
}

#DadosBasicos::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#DadosBasicos::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #DadosBasicos::-webkit-scrollbar {
    width: initial;
  }

  #DadosBasicos::-webkit-scrollbar-button {
    padding: initial;
  }

  #DadosBasicos::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #DadosBasicos::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.container {
  max-width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.text-max {
  /* display: inline-block;
    max-width: 20ch!important;
    margin-bottom: -6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

.btn {
  width: 100px;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  color: #ffffff;
  letter-spacing: 0px;
}

.btn-icon {
  font-size: 20px;
}
</style>
